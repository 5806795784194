import React from "react";
import {Engine} from "platform/engine/Engine";
import {
    CurrencyPayload,
    DoSubmitDepositAPMPayload,
    DoSubmitDepositCCPayload,
    SetBriteAccessToken,
    SetDepositAmount,
    SetDepositAmountError,
    SetDepositConfiguration,
    SetDepositCurrency,
    SetReceiptDetails, UpdateDepositConfiguration,
    ValidateDepositAmount
} from "core/redux/deposit/DepositReduxActions";
import Platform from "platform/Platform";
import {PageType} from "enum/PageType";
import {HideLoader, NavigateTo, SetLoader, SetTheme} from "platform/redux/core/CoreActions";
import {LoaderType} from "platform/enum/LoaderType";
import {ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupActionType, PopupIcon, PopupIconType, PopupType} from "platform/redux/popups/PopupsReduxState";
import {StoreState} from "core/redux/StoreState";
import {HttpReject} from "platform/network/http/Http";
import {
    ApmPaymentMethod,
    BankDetail,
    DepositCreditCard,
    DepositCurrency,
    DynamicInputFieldByKey,
    GetDepositConfigurationsResponse,
    PaymentProvider
} from "protocol/response/GetDepositConfigurationsResponse";
import Utils from "platform/util/Utils";
import {XhrManager} from "core/engine/XhrManager";
import WebUtil from "platform/util/WebUtil";
import {TSMap} from "typescript-map";
import {Currency} from "platform/enum/Currency";
import {
    SetBackNavigation,
    SetCallMe,
    SetCanManageLimits, SetHideBackToPlatformButton,
    SetKycContext,
    SetKycThirdPartyRedirect,
    SetKycUrl,
    SetModal,
    SetWebViewPlatform
} from "core/redux/app/AppReduxActions";
import {
    CreditCardPaymentDetails,
    DepositByCreditCardRequest,
} from "protocol/request/DepositByCreditCardRequest";
import {
    DepositByCreditCardError,
    DepositByCreditCardResponse
} from "protocol/request/DepositByCreditCardResponse";
import {FieldType} from "enum/FieldType";
import SubmitForm from "platform/network/form/SubmitForm";
import {PaymentMethod} from "enum/PaymentMethod";
import {ConvertPaymentAmountRequest} from "protocol/response/ConvertPaymentAmountRequest";
import {ConvertPaymentAmountResponse} from "protocol/response/ConvertPaymentAmountResponse";
import {TranslationKey} from "enum/TranslationKey";
import {StorageKey} from "enum/StorageKey";
import {DepositUtil} from "core/util/DepositUtil";
import {DepositByAPMRequest} from "protocol/request/DepositByAPMRequest";
import {DepositByAPMResponse} from "protocol/request/DepositByAPMResponse";
import {
    DepositVerifyAPMResponse,
    EWalletReceiptStatus,
    VerifyAPMError
} from "protocol/request/DepositVerifyAPMResponse";
import {TranslationParam} from "enum/TranslationParam";
import Parameter from "platform/util/Parameter";
import {LangCode} from "platform/enum/LangCode";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {ErrorCode} from "enum/ErrorCode";
import {DepositLimit} from "component/deposit/DepositLimit";
import {Increment} from "platform/util/Increment";
import {LoadLanguage} from "platform/redux/translation/TranslationActions";
import {ThemeType} from "platform/enum/ThemeType";
import {
    UrlAmount,
    UrlCurrency,
    UrlQueryStringRaw,
    UrlVerifyAPM, UrlVerifyCCEKey,
    UrlVerifyCCGuid,
    UrlWebView
} from "core/util/UrlConstants";
import Script from "platform/network/script/Script";
import {ReadyState} from "core/state/ReadyState";
import {ModalType} from "enum/ModalType";
import {ValidateDepositAmountRequest} from "protocol/request/ValidateDepositAmountRequest";
import {PaymentAmountError, ValidateDepositAmountResponse} from "protocol/response/ValidateDepositAmountResponse";
import {Img, ImgStatus} from "platform/network/img/Img";
import {Configuration} from "core/configuration/Configuration";
import {DepositVerifyAPMRequest} from "protocol/request/DepositVerifyAPMRequest";
import {BrandType} from "platform/enum/BrandType";
import {WebViewPlatformType} from "enum/WebViewPlatformType";
import {Bridge} from "core/integration/Bridge";
import {TokenManager} from "core/util/TokenManager";
import {InMemoryKeyValue} from "core/engine/InMemoryKeyValue";
import {RNBridge} from "core/integration/RNBridge";
import {LSKey} from "platform/storage/Storage";
import {BIEventType} from "enum/BIEventType";
import Translations from "platform/translation/Translations";
import {StringPayload} from "core/redux/StoreActions";
import {IntegrationOpenExternalUrl} from "platform/integration/message/IntegrationOpenExternalUrl";
import {CountryCode} from "platform/enum/CountryCode";
import {GetDepositConfigurationsRequest} from "protocol/response/GetDepositConfigurationsRequest";
import {EWalletDynamicDataType} from "enum/EWalletDynamicDataType";
import {ProceedWithCardDepositCascading} from "protocol/request/ProceedWithCardDepositCascading";
import {ConfigUtil} from "core/util/ConfigUtil";

const PopUpRemoteId: Increment = Increment.from(0);

export default class DepositEngine extends Engine {

    private static _instance: DepositEngine;
    private _apmAttempt: number = 0;
    private _InMemoryQueryString: string;

    public static instance(): DepositEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        this._logger.debug(`Is IFrame: ${WebUtil.isFrame()}. Is Safari: ${WebUtil.isSafari()}. Is IOS: ${WebUtil.isIOS()}. Is web mob: ${WebUtil.isMobile()}. Is RN: ${WebUtil.inRNWebView()}. GET webview param: ${UrlWebView}.`);
        if (Utils.isNotEmpty(UrlVerifyCCGuid) || UrlVerifyAPM) {
            let sQueryString: string = await Platform.storage().getItem(StorageKey.PreviousQueryString);
            if (RNBridge.hasBridge()) {
                this._InMemoryQueryString = await InMemoryKeyValue.Get(StorageKey.PreviousQueryString);
                if (Utils.isNotEmpty(this._InMemoryQueryString)) {
                    sQueryString = this._InMemoryQueryString;
                    InMemoryKeyValue.Set(StorageKey.PreviousQueryString, null);
                    Platform.storage().setItem(LSKey.Token, WebUtil.urlParam("token", sQueryString));
                    Platform.dispatch(SetKycThirdPartyRedirect({value: true}));
                }
            }
            if (Utils.isEmpty(UrlWebView)) {
                const webViewPlatform: WebViewPlatformType = WebUtil.urlParam("webview", sQueryString) as WebViewPlatformType;
                Platform.dispatch(SetWebViewPlatform({webViewPlatform}));
            }

            const langCode: LangCode = (WebUtil.urlParam("languageCode", sQueryString)
                || WebUtil.urlParam("language", sQueryString)
                || WebUtil.urlParam("lang", sQueryString)) as LangCode;
            const themeType: ThemeType = WebUtil.urlParam("theme", sQueryString) as ThemeType;
            if (langCode) {
                Platform.dispatch(LoadLanguage({langCode}));
            }
            if (themeType) {
                Platform.dispatch(SetTheme({themeType}));
            }
            const backNavigation: boolean = Utils.parseBoolean(WebUtil.urlParam("backNavigation", sQueryString));
            const kycContext: boolean = Utils.parseBoolean(WebUtil.urlParam("kycContext", sQueryString));
            Platform.dispatch(SetBackNavigation({value: backNavigation}));
            Platform.dispatch(SetKycContext({value: kycContext}));
            Platform.dispatch(SetModal({
                modalType: ModalType.PaymentProcessing,
                visible: true
            }));
            if (Utils.isNotEmpty(UrlVerifyCCGuid)) {
                const sBackToPlatformButton: string = await Platform.storage().getItem(StorageKey.BackToPlatformButton);
                Platform.storage().removeItem(StorageKey.BackToPlatformButton);
                if (Utils.parseBoolean(sBackToPlatformButton)) {
                    Platform.dispatch(SetHideBackToPlatformButton({}));
                }
                this.doVerify3DFlow().catch(() => {
                    this._logger.debug("Failed verify 3D");
                });
            } else if (UrlVerifyAPM) {
                this.doVerifyAPMFlow().catch(() => {
                    this._logger.debug("Failed verify APM");
                });
            }
        } else {
            Platform.storage().setItem(StorageKey.PreviousQueryString, UrlQueryStringRaw);
        }
    }

    private doVerify3DFlow = async () => {
        this._logger.debug("Verify 3D flow");
        const request: ProceedWithCardDepositCascading = {
            DepositRequestGuid: UrlVerifyCCGuid,
            EncryptionKey: UrlVerifyCCEKey,
            returnToUrl: window.location.origin
        };
        const answer: [HttpReject, DepositByCreditCardResponse] = await Utils.to(XhrManager.sendToBilling(request, "ProceedWithCardDepositCascading"));
        await this.doProcessDepositCCResponse(answer);
        Platform.dispatch(SetModal({
            modalType: ModalType.PaymentProcessing,
            visible: false
        }));
    }

    private doVerifyAPMFlow = async () => {
        let TransRefNum: string = await Platform.storage().getItem(StorageKey.APMTransRefNum);
        if (RNBridge.hasBridge()) {
            const InMemoryTransRefNum: string = await InMemoryKeyValue.Get(StorageKey.APMTransRefNum);
            this._logger.debug(`In memory TransRefNum: ${InMemoryTransRefNum}. LS TransRefNum: ${TransRefNum}`);
            if (Utils.isNotEmpty(InMemoryTransRefNum)) {
                TransRefNum = InMemoryTransRefNum;
                InMemoryKeyValue.Set(StorageKey.APMTransRefNum, null);
                Platform.storage().setItem(StorageKey.APMTransRefNum, TransRefNum);
            }
        }
        this._apmAttempt = 0;
        const FetchTransactionStatus = async () => {
            this._apmAttempt++;
            const status: number = await this.doGetDepositAPMStatus(TransRefNum);
            if (status !== EWalletReceiptStatus.Pending) {
                clearInterval(statusInterval);
                await this.doVerifyDepositAPMInternal(TransRefNum);
            } else if (this._apmAttempt === 5) {
                clearInterval(statusInterval);
                Platform.dispatch(SetModal({
                    modalType: ModalType.PaymentProcessing,
                    visible: false
                }));
                Platform.dispatch(SetModal({
                    modalType: ModalType.PaymentPending,
                    visible: true
                }));
            }
        };
        const statusInterval = setInterval(FetchTransactionStatus, 2000);
        await FetchTransactionStatus();
    }

    public onAppReady = async () => {
        this._logger.debug("Fetch deposit configurations");
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const response: GetDepositConfigurationsResponse = await this.doGetDepositConfigurations();
        if (response) {
            const {core, app} = Platform.reduxState<StoreState>();
            const brand: BrandType = core.brand;
            const Currencies: TSMap<Currency, DepositCurrency> = new TSMap<Currency, DepositCurrency>();
            let InitialCurrency: DepositCurrency;
            if (Utils.isArrayNotEmpty(response.Currencies)) {
                response.Currencies.forEach((depositCurrency: DepositCurrency) => {
                    Currencies.set(depositCurrency.Name, depositCurrency);
                    if (depositCurrency.Name === UrlCurrency) {
                        InitialCurrency = depositCurrency;
                    }
                });
                if (Utils.isNull(InitialCurrency)) {
                    InitialCurrency = response.Currencies[0];
                }
            }
            const {CreditCards, ApmPaymentMethods, BackToPlatformUrl, BankDetails, ClientDetails, NewOnboardingComplianceURL} = response;
            const PaymentMethods: Array<string | number> = [];
            if (Utils.isArrayNotEmpty(CreditCards)) {
                PaymentMethods.push(PaymentMethod.CreditCard);
                if (CreditCards.filter((cc: DepositCreditCard) => cc.Id === 13)[0]) {
                    PaymentMethods.push(PaymentMethod.OtherCreditCard);
                }
            }
            const PaymentProviders: TSMap<string | number, PaymentProvider> = new TSMap<string | number, PaymentProvider>();
            const PaymentProviderGroups: TSMap<string, PaymentProvider[]> = new TSMap<string, PaymentProvider[]>();
            const PaymentProviderIdPerEWalletId: TSMap<string | number, number> = new TSMap<string, number>();
            PaymentProviderGroups.set("EWallet", []);
            PaymentProviderGroups.set("Cash", []);
            PaymentProviderGroups.set("CreditCard", []);
            PaymentProviderGroups.set("Crypto", []);
            if (Utils.isArrayNotEmpty(ApmPaymentMethods)) {
                const {kycContext} = Platform.reduxState<StoreState>().app;
                ApmPaymentMethods.forEach((apm: ApmPaymentMethod) => {
                    if (Utils.isArrayNotEmpty(apm.PaymentProviders)) {
                        const groupProviders: PaymentProvider[] = PaymentProviderGroups.get(apm.Method) || [];
                        PaymentProviderGroups.set(apm.Method, groupProviders);
                        apm.PaymentProviders.forEach((pp: PaymentProvider) => {
                            const isApplePay: boolean = DepositUtil.isApplePay(pp);
                            const isGooglePay: boolean = DepositUtil.isGooglePay(pp);
                            const isPayRetailer: boolean = DepositUtil.isPayRetailer(pp);
                            const iosWebView: boolean = app.webViewPlatform === WebViewPlatformType.IOS;
                            const androidWebView: boolean = app.webViewPlatform === WebViewPlatformType.Android;
                            if ((isApplePay && !kycContext && (WebUtil.isSafari() || iosWebView))
                                || (isGooglePay && ((!WebUtil.isSafari() && !iosWebView) || androidWebView))
                                || (!isApplePay && !isGooglePay))
                            {
                                const Id: string | number = DepositUtil.ApmId(pp);
                                if (isPayRetailer) {
                                    if (PaymentMethods.indexOf(PaymentMethod.PayRetailers) < 0) {
                                        PaymentMethods.push(PaymentMethod.PayRetailers);
                                    }
                                } else {
                                    PaymentMethods.push(Id);
                                }
                                PaymentProviders.set(Id, pp);
                                if (brand === BrandType.Sencillo) {
                                    groupProviders.push(pp);
                                } else if (isPayRetailer) {
                                    groupProviders.push(pp);
                                }
                                if (pp.ExternalPaymentProviderId) {
                                    PaymentProviderIdPerEWalletId.set(pp.ExternalPaymentProviderId, pp.EWalletId);
                                }
                                if (isApplePay) {
                                    Script.injectScript("https://cdn.safecharge.com/safecharge_resources/v1/sc_api_applepay.min.js", "ApplePaySdk", () => {
                                        this._logger.debug("ApplePaySdk loaded");
                                    });
                                }
                            }
                        });
                    }
                });
            }
            const BanksCurrencies: Currency[] = [];
            const Banks: TSMap<Currency, BankDetail[]> = new TSMap<Currency, BankDetail[]>();
            const BankSelection: TSMap<Currency, BankDetail> = new TSMap<Currency, BankDetail>();
            if (Utils.isArrayNotEmpty(BankDetails)) {
                PaymentMethods.push(PaymentMethod.WireTransfer);
                BankDetails.forEach((bank: BankDetail) => {
                    if (BanksCurrencies.indexOf(bank.AssetName) < 0) {
                        BanksCurrencies.push(bank.AssetName);
                    }
                    const banks: BankDetail[] = Banks.get(bank.AssetName) || [];
                    banks.push(bank);
                    Banks.set(bank.AssetName, banks);
                });
                BanksCurrencies.sort((c1: Currency, c2: Currency) => Utils.compareString(c1, c2));
                Banks.forEach((banks: BankDetail[], currency: Currency) => {
                    banks.sort((b1: BankDetail, b2: BankDetail) => Utils.compareString(b1.Name, b2.Name));
                    BankSelection.set(currency, banks[0]);
                });
            }

            const isSwedenUser: boolean = ClientDetails?.CountryCodeByIpAddress === CountryCode.Sweden;
            if (isSwedenUser) {
                PaymentMethods.sort((m1: number | string, m2: number | string) =>
                    Utils.compareNumber(DepositUtil.SwedenPaymentPriority(m2), DepositUtil.SwedenPaymentPriority(m1)));
            }
            Platform.dispatch(SetDepositConfiguration({
                Currencies,
                CreditCards: CreditCards?.sort((cc1: DepositCreditCard, cc2: DepositCreditCard) => Utils.compareNumber(cc1?.Id, cc2?.Id)),
                PaymentMethods,
                PaymentProviders,
                PaymentProviderGroups,
                PaymentProviderIdPerEWalletId,
                PlatformUrl: ConfigUtil.UseAddressBarDomain(BackToPlatformUrl),
                BanksCurrencies,
                Banks,
                BankSelection,
                Client: ClientDetails,
                ChargeInDifferentCurrencyDisclaimer: response.ChargeInDifferentCurrencyDisclaimer
            }));
            Platform.dispatch(SetKycUrl({value: ConfigUtil.UseAddressBarDomain(NewOnboardingComplianceURL)}));
            if (Utils.isNotNull(UrlAmount)) {
                Platform.dispatch(SetDepositAmount({
                    value: UrlAmount
                }));
            } else {
                const SuggestedAmounts: number[] = DepositUtil.GetSuggestedAmounts(InitialCurrency, brand);
                if (Utils.isArrayNotEmpty(SuggestedAmounts) && brand === BrandType.Sencillo) {
                    Platform.dispatch(SetDepositAmount({
                        value: SuggestedAmounts[0]
                    }));
                }
            }
            Platform.dispatch(SetDepositCurrency({
                currency: InitialCurrency?.Name
            }));
            Platform.dispatch(SetCallMe({value: false}));
            ReadyState.hasDepositConfigs = true;

            setInterval(this.doUpdateDepositConfigurations, 30 * 1000);
        }
        Platform.dispatch(HideLoader({}));
    }

    private doGetDepositConfigurations = async (): Promise<GetDepositConfigurationsResponse> => {
        const request: GetDepositConfigurationsRequest = {
            CultureName: LanguageUtil.languageCode()
        };
        const answer: [HttpReject, GetDepositConfigurationsResponse] = await Utils.to(XhrManager.sendToBilling(request, "GetDepositConfigurations"));
        if (answer[0]) {
            this._logger.warn("Failed fetch deposit configurations");
        } else {
            return answer[1];
        }
        return null;
    }

    private doUpdateDepositConfigurations = async () => {
        const response: GetDepositConfigurationsResponse = await this.doGetDepositConfigurations();
        if (Utils.isArrayNotEmpty(response?.Currencies)) {
            const Currencies: TSMap<Currency, DepositCurrency> = new TSMap<Currency, DepositCurrency>();
            response.Currencies.forEach((depositCurrency: DepositCurrency) => {
                Currencies.set(depositCurrency.Name, depositCurrency);
            });
            Platform.dispatch(UpdateDepositConfiguration({
                Currencies
            }));
        }
    }

    public onTryChangeCurrency = async ({currency}: CurrencyPayload) => {
        const {Amount, SelectedCurrency, Currencies} = Platform.reduxState<StoreState>().deposit;
        this._logger.debug(`Try change currency to: ${currency}. Was currency: ${SelectedCurrency}. Amount: ${Amount}`);
        if (Amount) {
            const HasCurrencyFrom: boolean = Currencies.keys().indexOf(SelectedCurrency) > -1;
            const HasCurrencyTo: boolean = Currencies.keys().indexOf(currency) > -1;
            if (HasCurrencyFrom && HasCurrencyTo) {
                Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                const request: ConvertPaymentAmountRequest = {
                    Amount,
                    FromAssetId: Currencies.get(SelectedCurrency)?.Id,
                    ToAssetId: Currencies.get(currency)?.Id
                };
                const answer: [HttpReject, ConvertPaymentAmountResponse] = await Utils.to(XhrManager.sendToBilling(request, "ConvertPaymentAmount"));
                Platform.dispatch(HideLoader({}));
                if (answer[0]) {
                    this._logger.warn("Failed convert amount");
                } else {
                    const {ConvertedAmount, HasErrors} = answer[1];
                    if (HasErrors) {
                        this._logger.warn("Failed convert amount. Has errors");
                    } else {
                        const {amountServerValidation} = Platform.config<Configuration>();
                        Platform.dispatch(SetDepositAmount({value: ConvertedAmount}));
                        Platform.dispatch(SetDepositCurrency({currency}));
                        if (amountServerValidation) {
                            Platform.dispatch(ValidateDepositAmount({}));
                        }
                    }
                }
            } else {
                this._logger.warn(`Don't have ${currency} configured on server. Proceed without amount conversion`);
                Platform.dispatch(SetDepositCurrency({currency}));
            }
        } else {
            Platform.dispatch(SetDepositCurrency({currency}));
        }
    }

    public doSubmitDepositCC = async ({form}: DoSubmitDepositCCPayload) => {
        this._logger.debug("Submit deposit with CC");
        const langCode: LangCode = LanguageUtil.languageCode();
        const {deposit} = Platform.reduxState<StoreState>();
        const {Amount, SelectedCurrency, Currencies, creditCardType, Client} = deposit;
        const depositCurrency: DepositCurrency = Currencies?.values().filter((c: DepositCurrency) => c.Name === SelectedCurrency)[0];
        const FirstLastNames: string[] = form[FieldType.CardName]?.split(" ") || [];
        const century = new Date().getFullYear().toString().substring(0, 2);
        const expiration: string[] = form[FieldType.CardExpiration].split("/");
        Platform.bi().track(BIEventType.DepositAttempt, {
            PaymentMethod: "Credit card",
            CreditCardType: creditCardType,
            SelectedOtherCC: deposit.PaymentMethod === PaymentMethod.OtherCreditCard ? "yes" : "no",
            PaymentAmount: Amount,
            PaymentCurrency: SelectedCurrency
        });

        const paymentDetails: CreditCardPaymentDetails = {
            amount: Amount,
            paymentAssetName: SelectedCurrency,
            currencyId: depositCurrency?.Id,
            cardNumber: form[FieldType.CardNumber].replace(/\s/g, ""),
            firstName: FirstLastNames[0],
            lastName: FirstLastNames[1],
            nameOnCard: form[FieldType.CardName],
            cvv: form[FieldType.CardCvv],
            expMonth: parseInt(expiration[0]),
            expYear: parseInt(`${century}${expiration[1]}`),
            creditCardType: DepositUtil.CCFlowType(deposit.PaymentMethod),
            creditCardProvider: creditCardType,
            bonusPromotionCode: form[FieldType.PromotionCode],
        };
        const request: DepositByCreditCardRequest = {
            CultureName: langCode,
            paymentDetails,
            clientDetails: {
                firstName: FirstLastNames[0],
                lastName: FirstLastNames[1],
                address: Client?.Address || form[FieldType.Address],
                city: Client?.City || form[FieldType.City],
                countryCode: Client?.CountryCode || form[FieldType.CountryCode],
                emailAddress: null,
                langCode: null,
                phoneNumber: Client?.PhoneNumber,
                zipCode: Client?.ZipCode || form[FieldType.ZipCode],
            },
            DeviceData: DepositUtil.GetDeviceData(),
            returnToUrl: window.location.origin
        };
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, DepositByCreditCardResponse] = await Utils.to(XhrManager.sendToBilling(request, "InitializeDepositByCard"));
        Platform.dispatch(HideLoader({}));
        await this.doProcessDepositCCResponse(answer, paymentDetails);
    }

    private doProcessDepositCCResponse = async (answer: [HttpReject, DepositByCreditCardResponse], paymentDetails?: CreditCardPaymentDetails) => {
        if (answer[0]) {
            this._logger.warn("Failed make deposit by CC");
            if (paymentDetails) {
                Platform.bi().track(BIEventType.DepositFailedUI, {
                    PaymentMethod: "Credit card",
                    CreditCardType: paymentDetails.creditCardProvider,
                    SelectedOtherCC: paymentDetails.creditCardType === "Other" ? "yes" : "no",
                    PaymentAmount: paymentDetails.amount,
                    PaymentCurrency: paymentDetails.paymentAssetName,
                    UIErrormessage: Translations.TextEn(TranslationKey.serverErrorGeneral)
                });
            }
            XhrManager.notifyRejectReason(answer);
        } else {
            const {HasErrors, Errors, RequestForm, ReceiptDetails} = answer[1];
            if (HasErrors) {
                this._logger.debug("Deposit attempt with errors");
                const message: string[] = [];
                const errMessages: string[] = [];
                let hasDepositLimitError: boolean;
                if (Utils.isArrayNotEmpty(Errors)) {
                    Errors.forEach((error: DepositByCreditCardError, index: number) => {
                        message.push(error.ErrorDescription);
                        errMessages.push(error.ErrorDescription);
                        if (parseInt(error.ErrorCode) === ErrorCode.DepositLimits) {
                            hasDepositLimitError = true;
                        }
                        if (index < Errors.length - 1) {
                            message.push("<br/>");
                        }
                    });
                }
                if (hasDepositLimitError) {
                    Platform.dispatch(SetCanManageLimits({value: true}));
                }
                if (paymentDetails) {
                    Platform.bi().track(BIEventType.DepositFailedUI, {
                        PaymentMethod: "Credit card",
                        CreditCardType: paymentDetails.creditCardProvider,
                        SelectedOtherCC: paymentDetails.creditCardType === "Other" ? "yes" : "no",
                        PaymentAmount: paymentDetails.amount,
                        PaymentCurrency: paymentDetails.paymentAssetName,
                        UIErrormessage: Utils.isArrayNotEmpty(errMessages) ? errMessages.join(", ") : Translations.TextEn(TranslationKey.serverErrorGeneral),
                        InternalErrorMessage: Utils.isArrayNotEmpty(errMessages) ? errMessages.join(", ") : null
                    });
                }
                const remoteId: number = PopUpRemoteId.next();
                Platform.dispatch(ShowPopup({
                    popup: {
                        remoteId,
                        type: PopupType.ERROR,
                        message: {
                            customValue: message.join(""),
                            trKey: TranslationKey.serverErrorGeneral
                        },
                        body: {
                            extra: hasDepositLimitError ? <DepositLimit popUpId={remoteId} /> : null
                        },
                        showClose: true,
                        icon: {type: PopupIconType.ERROR},
                        actions: [{type: PopupActionType.OK}]
                    }
                }));
            } else {
                if (RequestForm) {
                    this._logger.debug("Submitting form to: " + RequestForm.SubmitFormUrl);
                    const {SubmitFormUrl, FormMethod, FieldData} = RequestForm;
                    const {core, app} = Platform.reduxState<StoreState>();
                    const IsKycWebView: boolean = app.kycContext && Utils.isNotEmpty(app.webViewPlatform);
                    if (app.hideBackToPlatformButton) {
                        Platform.storage().setItem(StorageKey.BackToPlatformButton, "true");
                    }
                    if (IsKycWebView) {
                        const langCode: LangCode = LanguageUtil.languageCode();
                        const token: string = await TokenManager.token();
                        let QueryString: string = `https://?token=${token}&theme=${core.theme}&lang=${langCode}`;
                        if (Utils.isNotNull(app.webViewPlatform)) {
                            QueryString = WebUtil.addGetParam(QueryString, "webview", app.webViewPlatform);
                        }
                        Bridge.Instance().setInMemory(StorageKey.PreviousQueryString, QueryString);
                        let RedirectUrl: string = `https://${window.location.hostname}/SubmitForm.html?SubmitFormUrl=${encodeURIComponent(SubmitFormUrl)}&FormMethod=${FormMethod.toLowerCase()}`;
                        if (Utils.isArrayNotEmpty(FieldData)) {
                            const parameters: string[] = [];
                            FieldData.forEach((field: {Key: string, Value: string}) => {
                                parameters.push(`${field.Key}=${field.Value}`);
                            });
                            RedirectUrl = WebUtil.addGetParam(RedirectUrl, "FieldData", encodeURIComponent(parameters.join(",")));
                        }
                        window.top.location.href = RedirectUrl;
                    } else {
                        if (Utils.isNotEmpty(app.webViewPlatform)) {
                            InMemoryKeyValue.Set(StorageKey.PreviousQueryString, this._InMemoryQueryString);
                        }
                        const parameters: { [key: string]: string } = {};
                        if (Utils.isArrayNotEmpty(FieldData)) {
                            FieldData.forEach((field: {Key: string, Value: string}) => {
                                parameters[field.Key] = field.Value;
                            });
                        }
                        SubmitForm.submit(SubmitFormUrl, FormMethod, parameters);
                    }
                } else {
                    if (ReceiptDetails) {
                        this._logger.debug("Non 3D secure successful deposit");
                        Platform.dispatch(SetReceiptDetails({CreditCardReceiptDetails: ReceiptDetails}));
                        Platform.dispatch(NavigateTo({route: PageType.DepositSuccessful}));
                    } else {
                        Platform.dispatch(ShowPopup({
                            popup: {
                                type: PopupType.ERROR,
                                message: {
                                    trKey: TranslationKey.serverErrorGeneral
                                },
                                showClose: true,
                                icon: {type: PopupIconType.ERROR},
                                actions: [{type: PopupActionType.OK}]
                            }
                        }));
                    }
                }
            }
        }
    }

    public doSubmitDepositAPM = async ({form, token, completion}: DoSubmitDepositAPMPayload) => {
        const {core, app, deposit} = Platform.reduxState<StoreState>();
        const {Amount, SelectedCurrency, PaymentMethod: paymentMethod, PaymentProviders, SelectedPaymentProvider, Client} = deposit;
        const IsPayRetailers: boolean = paymentMethod === PaymentMethod.PayRetailers;
        const provider: PaymentProvider = IsPayRetailers ? SelectedPaymentProvider.get(paymentMethod) : PaymentProviders.get(paymentMethod);
        if (provider) {
            Platform.bi().track(BIEventType.DepositAttempt, {
                PaymentMethod: "E-Wallet",
                PaymentMethodName: provider.DisplayName,
                PaymentAmount: Amount,
                PaymentCurrency: SelectedCurrency
            });
            const langCode: LangCode = LanguageUtil.languageCode();
            let UrlRedirect: string = `https://${window.location.hostname}/?eVerify=true&theme=${core.theme}&lang=${langCode}`;
            if (app.kycContext) {
                UrlRedirect = WebUtil.addGetParam(UrlRedirect, "kycContext", "true");
            }
            if (Utils.isNotNull(app.webViewPlatform)) {
                UrlRedirect = WebUtil.addGetParam(UrlRedirect, "webview", app.webViewPlatform);
            }
            const InputFields: {[key: string]: string | number} = {};
            if (!Utils.isObjectEmpty(provider.DynamicInputFieldsByKey)) {
                Object.keys(provider.DynamicInputFieldsByKey).forEach((key: string) => {
                    const DynamicField: DynamicInputFieldByKey = provider.DynamicInputFieldsByKey[key];
                    if (DynamicField.DynamicDataType !== EWalletDynamicDataType.Hidden) {
                        InputFields[key] = form[key];
                    }
                });
            }
            const request: DepositByAPMRequest = {
                CultureName: LanguageUtil.languageCode(),
                Amount,
                Currency: SelectedCurrency,
                EWalletId: provider.EWalletId,
                EWalletAccountId: provider.EWalletAccountId,
                ExternalPaymentProviderId: Utils.isNotNull(token) ? JSON.stringify(token) : provider.ExternalPaymentProviderId,
                UrlRedirect,
                BonusPromotionCode: form[FieldType.PromotionCode],
                ClientDetails: DepositUtil.BuildAPMClientDetails(Client, form),
                DeviceData: DepositUtil.GetDeviceData(),
                InputFields,
                returnToUrl: window.location.origin
            };
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            const answer: [HttpReject, DepositByAPMResponse] = await Utils.to(XhrManager.sendToBilling(request, "PerformExternalAPICall"));
            Platform.dispatch(HideLoader({}));
            if (answer[0]) {
                this._logger.warn("Failed make deposit by APM");
                Platform.bi().track(BIEventType.DepositFailedUI, {
                    PaymentMethod: "E-Wallet",
                    PaymentMethodName: provider.DisplayName,
                    PaymentAmount: Amount,
                    PaymentCurrency: SelectedCurrency,
                    UIErrormessage: Translations.TextEn(TranslationKey.serverErrorGeneral),
                });
                if (completion) {
                    completion(false);
                }
                XhrManager.notifyRejectReason(answer, (errorCode: number) => {
                    const hasDepositLimitError: boolean = errorCode === ErrorCode.DepositLimits;
                    if (hasDepositLimitError) {
                        Platform.dispatch(SetCanManageLimits({value: true}));
                    }
                    const remoteId: number = PopUpRemoteId.next();
                    return {
                        remoteId,
                        extra: hasDepositLimitError ? <DepositLimit popUpId={remoteId} /> : null,
                    };
                });
            } else {
                const {TransRefNum, ErrorDescription, SdkData} = answer[1];
                if (answer[1].ErrorCode > 0 || Utils.isNotEmpty(ErrorDescription)) {
                    if (completion) {
                        completion(false);
                    }
                    const hasDepositLimitError: boolean = answer[1].ErrorCode === ErrorCode.DepositLimits;
                    if (hasDepositLimitError) {
                        Platform.dispatch(SetCanManageLimits({value: true}));
                    }
                    Platform.bi().track(BIEventType.DepositFailedUI, {
                        PaymentMethod: "E-Wallet",
                        PaymentMethodName: provider.DisplayName,
                        PaymentAmount: Amount,
                        PaymentCurrency: SelectedCurrency,
                        UIErrormessage: ErrorDescription ? ErrorDescription : Translations.TextEn(TranslationKey.serverErrorGeneral),
                        InternalErrorMessage: ErrorDescription
                    });
                    const remoteId: number = PopUpRemoteId.next();
                    Platform.dispatch(ShowPopup({
                        popup: {
                            remoteId,
                            type: PopupType.ERROR,
                            message: {
                                customValue: ErrorDescription,
                                trKey: TranslationKey.serverErrorGeneral
                            },
                            body: {
                                extra: hasDepositLimitError ? <DepositLimit popUpId={remoteId} /> : null
                            },
                            showClose: true,
                            icon: {type: PopupIconType.ERROR},
                            actions: [{type: PopupActionType.OK}]
                        }
                    }));
                } else {
                    if (completion) {
                        completion(true);
                    }
                    Platform.storage().setItem(StorageKey.APMTransRefNum, TransRefNum);
                    if (DepositUtil.isApplePay(provider)) {
                        await this.doVerifyDepositAPMInternal(TransRefNum);
                    } else if (DepositUtil.isBrite(provider)) {
                        this._logger.debug("Proceed with Brite SDK");
                        Utils.checkNotNull(SdkData?.AccessToken);
                        Platform.dispatch(SetBriteAccessToken({
                            value: SdkData.AccessToken
                        }));
                    } else {
                        const IsInsideApp: boolean = Platform.config<Configuration>().APMInsideApp.indexOf(provider.EWalletId) > -1;
                        const token: string = await TokenManager.token();
                        const IsKycWebView: boolean = app.kycContext && Utils.isNotEmpty(app.webViewPlatform);
                        let QueryString: string = `https://?token=${token}&theme=${core.theme}&lang=${langCode}`;
                        if (Utils.isNotNull(app.webViewPlatform)) {
                            QueryString = WebUtil.addGetParam(QueryString, "webview", app.webViewPlatform);
                        }
                        if (IsKycWebView && IsInsideApp) {
                            Bridge.Instance().setInMemory(StorageKey.PreviousQueryString, QueryString);
                            Bridge.Instance().setInMemory(StorageKey.APMTransRefNum, TransRefNum);
                            window.top.location.href = DepositUtil.BuildAPMTargetUrl(token, TransRefNum, QueryString, answer[1]);
                        } else if (Utils.isNotEmpty(app.webViewPlatform) && IsInsideApp) {
                            InMemoryKeyValue.Set(StorageKey.PreviousQueryString, this._InMemoryQueryString);
                            Platform.environment().redirect(
                                DepositUtil.BuildAPMTargetUrl(token, TransRefNum, QueryString, answer[1])
                            );
                        } else {
                            const icon: PopupIcon = {
                                clazzWrapper: "PaymentProvider"
                            };
                            if (IsPayRetailers) {
                                icon.type = PopupIconType.INFO;
                                icon.clazz = "pay-retailers";
                            } else {
                                const ppIconUrl: string = `${Platform.config<Configuration>().amazonPathIcon}deposit/PaymentProviders/Popup/${provider.EWalletId}.png`;
                                this._logger.debug(`Fetching APM icon image status by URL: ${ppIconUrl}`);
                                const ppIconStatus: ImgStatus = await Img.Status(ppIconUrl, 3000);
                                this._logger.debug(`APM icon image: ${ppIconStatus}`);
                                icon.url = ppIconStatus === ImgStatus.Exist ? ppIconUrl : provider.ImageUrl;
                                icon.base64 = provider.ImageBase64;
                                icon.clazz = `_${provider.EWalletId}`;
                            }
                            Platform.dispatch(ShowPopup({
                                popup: {
                                    type: PopupType.INFO,
                                    clazz: "PaymentProvider",
                                    message: {
                                        trKey: TranslationKey.redirectToAPM,
                                        params: [Parameter.Of(TranslationParam.name, IsPayRetailers ? "PayRetailers" : provider.DisplayName)]
                                    },
                                    showClose: true,
                                    icon,
                                    actions: [{
                                        type: PopupActionType.OK,
                                        text: {trKey: TranslationKey.continue_},
                                        action: () => {
                                            const url: string = DepositUtil.BuildAPMTargetUrl(token, TransRefNum, QueryString, answer[1]);
                                            if (Utils.isNotEmpty(app.webViewPlatform)) {
                                                if (RNBridge.hasBridge()) {
                                                    RNBridge.send(JSON.stringify(new IntegrationOpenExternalUrl(url)));
                                                } else if (app.kycContext && WebUtil.isFrame()) {
                                                    Bridge.Instance().openUrl(url);
                                                }
                                            } else {
                                                Platform.environment().openWindow(url);
                                            }
                                            Platform.dispatch(NavigateTo({route: PageType.DepositTopUp}));
                                        }
                                    }]
                                }
                            }));
                        }
                    }
                }
            }
        } else {
            this._logger.warn("Payment provider absent for id: " + paymentMethod);
            if (completion) {
                completion(false);
            }
        }
    }

    private doGetDepositAPMStatus = async (TransRefNum: string): Promise<number> => {
        if (TransRefNum) {
            this._logger.debug("Fetch APM Transaction status by number: " + TransRefNum);
            const request: DepositVerifyAPMRequest = {
                CultureName: LanguageUtil.languageCode(),
                TransactionReferenceNumber: TransRefNum
            };
            const answer: [HttpReject, DepositVerifyAPMResponse] = await Utils.to(XhrManager.sendToBilling(request, "GetEWalletDepositReceipt"));
            if (Utils.greaterThen0(answer[1]?.StatusType)) {
                return answer[1].StatusType;
            }
        } else {
            this._logger.warn("Can't fetch APM Transaction status by number: " + TransRefNum);
        }
        return -1;
    }

    public doVerifyDepositAPM = async ({value}: StringPayload) => {
        await this.doVerifyDepositAPMInternal(value);
        Platform.dispatch(SetBriteAccessToken({
            value: null
        }));
    }

    private doVerifyDepositAPMInternal = async (TransRefNum: string) => {
        if (TransRefNum) {
            this._logger.debug("Verify APM by TransRefNum: " + TransRefNum);
            const {app, deposit} = Platform.reduxState<StoreState>();
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            const request: DepositVerifyAPMRequest = {
                CultureName: LanguageUtil.languageCode(),
                TransactionReferenceNumber: TransRefNum
            };
            const answer: [HttpReject, DepositVerifyAPMResponse] = await Utils.to(XhrManager.sendToBilling(request, "GetEWalletDepositReceipt"));
            Platform.dispatch(HideLoader({}));
            Platform.dispatch(SetModal({
                modalType: ModalType.PaymentProcessing,
                visible: false
            }));
            const OnFailAction = (): void => {
                if (UrlVerifyAPM) {
                    const {PaymentMethod: paymentMethod, PaymentProviders} = deposit;
                    if (window?.opener && !window.opener.closed) {
                        window.close();
                    } else if (Utils.isNotEmpty(app.webViewPlatform) && !DepositUtil.isBrite(PaymentProviders.get(paymentMethod))) {
                        Platform.environment().redirect("https://native-app-focus.thexcite.com?deposit=failed");
                    }
                }
            };
            if (answer[0]) {
                this._logger.warn("Failed verify APM");
                XhrManager.notifyRejectReason(answer, null, OnFailAction, UrlVerifyAPM);
            } else {
                const {HasErrors, Errors, StatusType} = answer[1];
                if (HasErrors) {
                    const message: string[] = [];
                    const errMessages: string[] = [];
                    if (Utils.isArrayNotEmpty(Errors)) {
                        Errors.forEach((error: VerifyAPMError, index: number) => {
                            message.push(error.ErrorDescription);
                            errMessages.push(error.ErrorDescription);
                            if (index < Errors.length - 1) {
                                message.push("<br/>");
                            }
                        });
                    }
                    const InternalErrorMessage: string = Utils.isArrayNotEmpty(errMessages) ? errMessages.join(", ") : null;
                    Platform.bi().track(BIEventType.DepositFailedUI, {
                        PaymentMethod: "E-Wallet",
                        TransactionReferenceNumber: TransRefNum,
                        UIErrormessage: InternalErrorMessage ? InternalErrorMessage : Translations.TextEn(TranslationKey.serverErrorGeneral),
                        InternalErrorMessage
                    });
                    Platform.dispatch(ShowPopup({
                        popup: {
                            type: PopupType.ERROR,
                            message: {
                                customValue: message.join(""),
                                trKey: TranslationKey.serverErrorGeneral
                            },
                            showClose: !UrlVerifyAPM,
                            icon: {type: PopupIconType.ERROR},
                            actions: [{type: PopupActionType.OK, action: OnFailAction}]
                        }
                    }));
                } else {
                    if (StatusType === EWalletReceiptStatus.Success) {
                        Platform.dispatch(SetReceiptDetails({APMReceiptDetails: answer[1]}));
                        Platform.dispatch(NavigateTo({route: PageType.DepositSuccessful, params: {eVerify: UrlVerifyAPM}}));
                    } else if (StatusType === EWalletReceiptStatus.Pending) {
                        Platform.dispatch(SetModal({
                            modalType: ModalType.PaymentPending,
                            visible: true
                        }));
                    } else {
                        Platform.bi().track(BIEventType.DepositFailedUI, {
                            PaymentMethod: "E-Wallet",
                            TransactionReferenceNumber: TransRefNum,
                            UIErrormessage: Translations.TextEn(TranslationKey.serverErrorGeneral),
                        });
                        Platform.dispatch(ShowPopup({
                            popup: {
                                type: PopupType.ERROR,
                                message: {
                                    trKey: TranslationKey.serverErrorGeneral
                                },
                                showClose: !UrlVerifyAPM,
                                icon: {type: PopupIconType.ERROR},
                                actions: [{type: PopupActionType.OK, action: OnFailAction}]
                            }
                        }));
                    }
                }
            }
        } else {
            this._logger.warn("APM TransRefNum absent");
        }
    }

    public doValidatePaymentAmount = async () => {
        const {Amount, SelectedCurrency, Currencies} = Platform.reduxState<StoreState>().deposit;
        const HasCurrency: boolean = Currencies.keys().indexOf(SelectedCurrency) > -1;
        if (Utils.isNotNull(Amount) && HasCurrency) {
            const request: ValidateDepositAmountRequest = {
                PaymentAmount: Amount,
                PaymentAssetId: Currencies?.values().filter((c: DepositCurrency) => c.Name === SelectedCurrency)[0]?.Id,
                CultureName: LanguageUtil.languageCode()
            };
            const answer: [HttpReject, ValidateDepositAmountResponse] = await Utils.to(XhrManager.sendToBilling(request, "ValidateDepositFields"));
            if (answer[1]) {
                const {HasErrors, Errors} = answer[1];
                if (HasErrors && Utils.isArrayNotEmpty(Errors)) {
                    const error: PaymentAmountError = Errors[0];
                    Platform.dispatch(SetDepositAmountError({error}));
                    if (parseInt(error.ErrorCode) === ErrorCode.DepositLimits) {
                        Platform.dispatch(SetCanManageLimits({value: true}));
                    }
                } else {
                    Platform.dispatch(SetDepositAmountError({error: null}));
                }
            } else {
                Platform.dispatch(SetDepositAmountError({error: null}));
            }
        }
    }
}
